@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hide scrollbar for Chrome, Safari and Opera */
.table-style::-webkit-scrollbar {
  display: none;
}

.custom-scroll::-webkit-scrollbar {
  width: 9px;
}

.custom-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  padding: 2px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background: #7a7a7a;
  border-radius: 10px;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-style {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-x: visible;
  overflow-y: auto;
}

.styled-select select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}
